* {
  box-sizing: border-box;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

html,
body {
  height: 100%;
  width: 100%;
}

/* body and #deal need to be flex containers/children for IE to work correctly */
body {
  font-display: swap;
  font-family: 'Circular', system-ui, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
}

:global(#root),
:global(#deal) {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-height: 0;
  min-width: 0;
  z-index: 1;
}

button,
input,
optgroup,
select,
textarea {
  font-display: swap;
  font-family: 'Circular', system-ui, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/**
 * Un-reset heading link, header, list, and svg styles. We rely on these browser default margins in many places in this app
 */
a {
  color: var(--c-secondary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  font-weight: bold;
  margin-left: 0;
  margin-right: 0;
}
h1 {
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}
h2 {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}
h3 {
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
}
h4 {
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}
h5 {
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}
h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}

ol,
ul {
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
ol {
  list-style-type: decimal;
}
ul {
  list-style-type: disc;
}

svg {
  display: block;
}
