@value mBreakpointSm, mBreakpointMd from '~#src/app/assets/styles/breakpoints.css';

.container {
  composes: px-2 py-3 from global;
}

.header {
  composes: text-center from global;
}

.desktop-icon {
  display: none;

  @media mBreakpointMd {
    display: inline;
  }
}

.mobile-icon {
  display: inline;
  width: 32px;
  height: 32px;

  @media mBreakpointMd {
    display: none;
  }
}

.image {
  composes: mx-auto mt-3 from global;
  width: 100%;

  @media mBreakpointSm {
    width: 66.7%;
  }

  & > img {
    width: 100%;
  }
}

.headline {
  composes: m-0 p-0 pt-3 text-center text-black from global;
  font-size: 20px;

  @media mBreakpointSm {
    font-size: 28px;
  }
}

.body {
  composes: m-0 p-0 pt-2 text-center text-med-gray from global;
  font-size: 16px;

  @media mBreakpointSm {
    font-size: 20px;
  }
}

.action {
  composes: mx-auto my-3 text-center from global;
}
