.toast-container {
  border-radius: 6px !important;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 10px 15px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff !important;
}

.success-container {
  composes: toast-container;
  border: solid 1px var(--c-black-10) !important;
}

.error-container {
  composes: toast-container;
  border: solid 1px var(--c-error) !important;
}

.toast-body {
  composes: p-1 from global;
  padding-top: 10px;
  align-items: start;
  padding-left: 100px;
  min-height: 80px;
  font-family: 'Circular', system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans,
    sans-serif, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  width: calc(100% - 16px);
  overflow-wrap: break-word;
}

.icon {
  composes: absolute from global;
  left: 16px;
  height: 82px;
  animation: shake 0.8s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

.success-icon {
  composes: icon;
  bottom: -5px;
  transform-origin: center bottom;
}

.error-icon {
  composes: icon;
  top: -5px;
  transform-origin: center top;
}

.success-body {
  composes: toast-body;
  color: #1d9b50;
}

.error-body {
  composes: toast-body;
  color: var(--c-error);
  white-space: pre-wrap;
}
