.modal {
  composes: fixed from global;
  z-index: 10000001;
  top: 56px;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  background: #ffffff;
  border: 1px solid var(--c-black-10);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 6px 6px;
}

.acceptedCall {
  composes: modal;
  border-top: 4px solid #0de163;
}

.connectingCall {
  composes: modal;
  border-top: 4px solid #f2a430;
}

.details {
  composes: flex justify-center p-2 from global;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-black-100);
}

.button-container {
  composes: flex from global;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: var(--c-black-100);
  border-top: 1px solid var(--c-black-10);
}

.button {
  composes: flex justify-center items-center py-1 from global;
  width: 100%;
  cursor: pointer;

  & > svg {
    margin-right: 8px;
  }

  &:not(:last-child) {
    border-right: 1px solid var(--c-black-10);
  }

  &:hover {
    border-top: 2px solid var(--c-black-100);
  }
}

.end-button {
  composes: button;

  &:hover {
    border-top: 2px solid var(--c-error);
  }
}

.keypad {
  border-top: 1px solid var(--c-black-10);
  padding: 12px;
}

.time {
  margin-left: 16px;
  /* Prevents time from jumping when the numbers have less width */
  min-width: 48px;
  color: rgba(26, 26, 26, 0.75);
}

.selected {
  background: var(--c-black-100);
  color: #fff;

  & path {
    fill: #fff;
  }
}

.loader {
  composes: ml-1 from global;
  flex-grow: 0;
  font-size: 6px;
}

.disabled {
  opacity: 0.4;
}
